import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import RichText from 'components/UIElements/RichText'
import Share from 'components/UIElements/Share'
// Models
import { IArticle } from 'models/Article'
// Styles
import * as styles from './ArticleContent.module.css'

type ArticleContentProps = {
    article: IArticle
}

const ArticleContent: React.FC<ArticleContentProps> = ({ article }) => {
    const { title, content } = article

    return (
        <main className={styles.article}>
            <Content className={styles.article_rich_text}>
                <RichText content={content} alt={title} />
                <Share message={title} />
            </Content>
            <DefaultSidebar />
        </main>
    )
}

export default ArticleContent
