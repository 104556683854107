import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Models
import { ISite } from 'models/SEO'
// Styles
import * as styles from './index.module.css'

type ShareProps = {
    message?: string
}

const Share: React.FC<ShareProps> = ({ message }) => {
    const { site }: ISite = useStaticQuery(query)
    const url =
        typeof window === 'undefined'
            ? ''
            : site.siteMetadata.siteUrl + location.pathname

    return (
        <section className={styles.share}>
            <h3 className={styles.share_title}>Compartir</h3>
            <div className={styles.share_buttons}>
                <a
                    href={`https://twitter.com/intent/tweet?url=${url}&via=${
                        site.siteMetadata.twitter
                    }${message ? `&text=${message}` : ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-social="twitter"
                >
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={['fab', 'twitter']}
                    />
                    Twitter
                </a>
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-social="facebook"
                >
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={['fab', 'facebook']}
                    />
                    Facebook
                </a>
                <a
                    href={`https://t.me/share/url?url=${
                        message ? `${message} ` : ''
                    }${url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-social="telegram"
                >
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={['fab', 'telegram']}
                    />
                    Telegram
                </a>
                <a
                    href={`https://web.whatsapp.com/send?phone&text=${url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-social="whatsapp"
                >
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={['fab', 'whatsapp']}
                    />
                    WhatsApp
                </a>
            </div>
        </section>
    )
}

export default Share

const query = graphql`
    {
        site {
            siteMetadata {
                siteUrl
                twitter
            }
        }
    }
`
