import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { getFormattedDate } from 'util/date'
// Models
import { IArticle } from 'models/Article'
// Styles
import * as styles from './ArticleHeader.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ArticleHeaderProps = {
    article: IArticle
}

const ArticleHeader: React.FC<ArticleHeaderProps> = ({ article }) => {
    const { title, thumbnail, categories, author, date } = article
    const created_date = getFormattedDate(date)
    return (
        <header className={styles.article_header}>
            <GatsbyImage
                className={styles.article_thumbnail}
                alt={title}
                loading="eager"
                image={thumbnail.gatsbyImageData}
            />
            <section className={styles.article_info}>
                <div className={styles.article_categories}>
                    {categories ? (
                        categories.map((category) => (
                            <Link
                                style={{ backgroundColor: category.color }}
                                key={category.slug}
                                className={styles.article_category}
                                to={`/categories/${category.slug}`}
                            >
                                {category.name}
                            </Link>
                        ))
                    ) : (
                        <Link
                            className={styles.article_category}
                            to={`/interviews`}
                        >
                            Entrevistas
                        </Link>
                    )}
                </div>
                <h2 className={styles.article_title}>{title}</h2>
                <div className={styles.article_author_and_date}>
                    <span>
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={['fas', 'user']}
                        />
                        <Link to={`/users/${author.username}`}>
                            {author.name}
                        </Link>
                    </span>
                    <span>
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={['far', 'calendar-alt']}
                        />
                        {created_date}
                    </span>
                </div>
            </section>
        </header>
    )
}

export default ArticleHeader
