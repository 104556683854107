import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import ArticleHeader from 'components/Sections/Article/ArticleHeader'
import ArticleContent from 'components/Sections/Article/ArticleContent'
// Models
import { IOptionalMetaProps } from 'models/SEO'
import { IArticle } from 'models/Article'

type ArticlePageProps = {
    article: IArticle
}
type Context = {
    slug: string
}

const ArticlePage: React.FC<PageProps<ArticlePageProps, Context>> = ({
    data,
}) => {
    const article = data.article
    const { title, excerpt, thumbnail } = article
    const seo: IOptionalMetaProps = {
        title,
        description: excerpt.text,
        image: thumbnail.file?.url,
    }

    return (
        <Layout seo={seo}>
            <ArticleHeader article={article} />
            <ArticleContent article={article} />
        </Layout>
    )
}

export default ArticlePage

export const pageQuery = graphql`
    query ArticlePage($slug: String!) {
        article: contentfulArticles(slug: { eq: $slug }) {
            title
            slug
            date
            content {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                    ... on ContentfulArticles {
                        contentful_id
                        title
                        slug
                        date
                        thumbnail {
                            gatsbyImageData(layout: CONSTRAINED)
                        }
                        __typename
                    }
                    ... on ContentfulSongs {
                        contentful_id
                        title: name
                        slug
                        release_date
                        thumbnail {
                            gatsbyImageData(layout: CONSTRAINED)
                        }
                        __typename
                    }
                }
            }
            author {
                name
                username
                avatar {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            categories {
                name
                slug
                color
            }
            excerpt {
                text: excerpt
            }
            thumbnail {
                gatsbyImageData(layout: FULL_WIDTH)
                file {
                    url
                }
            }
        }
    }
`
